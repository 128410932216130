/* eslint-disable import/max-dependencies */
import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading, P, Span } from "../../../packages/Typography/src";
import { Card } from "../../../packages/Card/src";
import Button from "../../../packages/Button/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import Icon from "../../../packages/Icon/src";

var ServicesSectionPattern = function ServicesSectionPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "sm",
    mt: "5rem"
  }, React.createElement(Grid, {
    alignItems: "center",
    gridRowGap: "2.5rem"
  }, React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 6"]
  }, React.createElement(Heading, {
    as: "h2",
    mt: "0"
  }, "Let us help you tell your story"), React.createElement(P, {
    fontSize: "md",
    muted: true
  }, "To kickstart a collaboration, all you need to do is send us an e-mail. We will get back to you to learn more about your needs."), React.createElement(Button, {
    variant: "primary",
    borderRadius: "pill",
    size: "lg"
  }, "Get started")), React.createElement(GridItem, {
    gridColumn: ["span 12", null, "span 6"]
  }, React.createElement(Card, {
    raised: true,
    interactive: true,
    borderRadius: "md",
    px: "1.5rem",
    py: "1rem",
    mb: "0.75rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Div, {
    width: "4rem",
    height: "4rem",
    bg: "yellow.100",
    borderRadius: "circle",
    mr: "1rem"
  }), React.createElement(Span, {
    size: ["sm", "md"]
  }, "Meet our team")), React.createElement(Icon, {
    name: "chevron_right"
  }))), React.createElement(Card, {
    raised: true,
    interactive: true,
    borderRadius: "md",
    px: "1.5rem",
    py: "1rem",
    mb: "0.75rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Div, {
    width: "4rem",
    height: "4rem",
    bg: "green.100",
    borderRadius: "circle",
    mr: "1rem"
  }), React.createElement(Span, {
    size: ["sm", "md"]
  }, "Vounteer for Datastory")), React.createElement(Icon, {
    name: "chevron_right"
  }))), React.createElement(Card, {
    raised: true,
    interactive: true,
    borderRadius: "md",
    px: "1.5rem",
    py: "1rem",
    mb: "0.75rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(Flex, {
    alignItems: "center"
  }, React.createElement(Div, {
    width: "4rem",
    height: "4rem",
    bg: "blue.100",
    borderRadius: "circle",
    mr: "1rem"
  }), React.createElement(Span, {
    fontSize: ["sm", "md"]
  }, "Read about our offer")), React.createElement(Icon, {
    name: "chevron_right"
  })))))));
};

export default ServicesSectionPattern;